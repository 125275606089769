import React, { useState, useEffect, useRef } from "react";
import { Select, Table, Modal, Spin, Tooltip, Button } from "antd";
import { BlobToDownloadFile } from "../../../helper/commanFunction";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";
import { Pie, getElementAtEvent } from "react-chartjs-2";
import traslate from "../../../i18n/translate";
import { useSelector } from "react-redux";
import ReportJewelryReportService from "../../../services/reports-Jewelry-service";
import moment from "moment";
import DefaultImage from "../../../assets/images/icons/sampleimage.jpg";
import AdminJewelryReportService from "../../../services/admin-jewelryReport-service";
import { DatePicker } from "antd";
const { RangePicker } = DatePicker;
const initialEventState = {
  dealerID: "",
  forDealer: "",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  retailerIds: [],
  vendorIds: [],
  vendor: "",
};

const JewelryVendorClickReportMain = () => {
  const JewelVendorChartRef = useRef();
  const { Option } = Select;

  const [dealerId, setDealerId] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [isApplyFilter, setIsApplyFilter] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [showTableLoader, setShowTableLoader] = useState(false);
  const [JewelryVendorClickTableList, setJewelryVendorClickTableList] =
    useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [visible, setVisible] = useState(false);
  const [defaultVisible, setDefaultVisible] = useState(false);
  const [retailerList, setRetailerList] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [topVendorDealer, setTopVendorDealer] = useState([]);
  const [topVendorDealerId, setTopVendorDealerId] = useState([]);
  const [topVendorTotal, setTopVendorTotal] = useState([]);
  const [TooltipPercentage, setTooltipPercentage] = useState("");
  const [totalValueForPercentage, setTotalValueForPercentage] = useState(0);
  const [key, setKey] = useState(true);
  const [dealerMembershipType, setDealerMembershiptype] = useState("");
  const [sortOrderType, setsortOrderType] = useState("desc");
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [downloadCSVDataloading, setDownloadCSVDataloading] = useState(false);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setDealerId(loginDetials.loginDetials.responseData.dealerId);
          handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
          setDealerMembershiptype(
            loginDetials.loginDetials.responseData.dealerMemberTypeName
          );
        }
      }
    }
    handleGetRetailerDropdownData();
  }, []);

  useEffect(() => {
    if (selectedData.vendor){
       let forDealerValue;
        if (loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor") {
          forDealerValue = 1;
        } else {
          forDealerValue = 3;
        }
        handleGetTableChartData(dealerId, forDealerValue);
        setPageNo(1);
        setPageSize(10);

    }
  }, [selectedData]);

  const handleGetForDealer = (id) => {
    try {
      const initialEventState = {
        dealerID: id,
      };
      setShowLoader(true);
      ReportJewelryReportService.GetForDealer(initialEventState).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            let forDealerValue;
            if (
              loginDetials.loginDetials.responseData.dealerMemberTypeName ===
              "Vendor"
            ) {
              forDealerValue = 1;
            } else {
              forDealerValue = 3;
            }
            setSelectedData((prevState) => ({
              ...prevState,
              forDealer: responseData.forDealer,
            }));
            handleGetTopVendorCoulmnData(id, forDealerValue);
            handleGetTableChartData(id, forDealerValue);
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };
  const handleGetTopVendorCoulmnData = (DealerId, forDealerId) => {
    try {
      const initialEventState = {
        ...selectedData,
        dealerID: DealerId.toString(),
        forDealer: forDealerId.toString(),
        fromDate: selectedData.fromDate,
        toDate: selectedData.toDate,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        reporttype: "Dealer",
      };
      setShowLoader(true);
      ReportJewelryReportService.GetClicksBasedOnTopVendor(
        initialEventState
      ).then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData.Table;
        if (message === "Success") {
          let dealerName = [];
          let dealerIds = [];
          let total = [];

          let showResp = responseData.every((element) => {
            return element.Total == "0";
          });
          setShowChart(showResp);
          for (let i of responseData) {
            dealerName.push(i.DealerCompany);
            dealerIds.push(i.VendorID);
            total.push(i.Total);
          }

          var JewelVendorYAxisList = responseData.map((item) => {
            return parseInt(item.Total);
          });
          var totalValueForPercentage = JewelVendorYAxisList.reduce(
            (a, b) => a + b,
            0
          );
          setTopVendorDealerId(dealerName);
          setTotalValueForPercentage(totalValueForPercentage);
          setTopVendorTotal(total);
          setTopVendorDealer(dealerName);
          setShowLoader(false);
          setKey(!key);
        } else {
          setShowLoader(false);
        }
      });
    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  };

  const [totalCount, setTotalCount] = useState(0);

  const handleGetTableChartData = (
    DealerId,
    forDealerId,
    pageNumber,
    pageSize,
    sortColumn,
    sorttype
  ) => {
    try {
      let inputData = {
        dealerID: DealerId.toString(),
        forDealer: forDealerId ? forDealerId.toString() : "",
        fromDate: selectedData.fromDate,
        toDate: selectedData.toDate,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        category: "",
        metalType: "",
        metalColorType: "",
        price: "",
        vendor: "",
        retailer: selectedData.vendor.toString(),
        pageIndex: pageNumber ? pageNumber : 1,
        pageSize: pageSize ? pageSize : 10,
        sortColumnName:
          sortColumn == "UserIPAddress"
            ? "UserIPAddress"
            : sortColumn == "vendorName"
            ? "VendorName"
            : sortColumn == "retailerName"
            ? "RetailerName"
            : sortColumn == "collectionNew"
            ? "CollectionNew"
            : sortColumn == "category"
            ? "Category"
            : sortColumn == "date"
            ? "Date"
            : sortColumn == "styleNumber"
            ? "StyleNumber"
            : sortColumn == "metalType"
            ? "MetalType"
            : sortColumn == "metalColor"
            ? "MetalColor"
            : sortColumn == "price"
            ? "Price"
            : "date",
        sortOrder: sorttype ? sorttype.toString() : sortOrderType.toString(),
        reporttype: "Dealer",
      };
      setShowTableLoader(true);
      ReportJewelryReportService.GetJewelryTableChartData(inputData)
        .then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            setTotalCount(responseData?.[0]?.totalRecords || 0);
            setJewelryVendorClickTableList(responseData);
            setShowTableLoader(false);
          } else {
            setTotalCount(0);

            setJewelryVendorClickTableList([]);
            setShowTableLoader(false);
          }
        })
        .catch((error) => {
          setTotalCount(0);
          setShowTableLoader(false);
          console.log(error);
        });
    } catch (error) {
      setTotalCount(0);
      console.log(error);
      setShowTableLoader(false);
    }
  };

  const imageClickHandler = (img) => {
    setVisible(true);
    setSelectedImage(img);
  };

  const handleApplyFilter = () => {
    handleGetForDealer(dealerId);
    console.log(initialEventState);
    setIsApplyFilter(!isApplyFilter);
  };

  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        fromDate: moment.utc(dates[0]).toISOString(),
        toDate: moment.utc(dates[1]).toISOString(),
      }));
    }
  };

  const handleExportToCSV = (sortColumn, sorttype) => {
    try {
      let forDealerValue = "";
      if (
        loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor"
      ) {
        forDealerValue = 1;
      } else {
        forDealerValue = 3;
      }
      const inputData = {
        dealerID: dealerId.toString(),
        forDealer: forDealerValue.toString(),
        fromDate: selectedData.fromDate,
        toDate: selectedData.toDate,
        retailerIds: selectedData.retailerIds.toString(),
        vendorIds: selectedData.vendorIds.toString(),
        category: "",
        metalType: "",
        metalColorType: "",
        price: "",
        vendor: "",
        retailer: "",
        pageIndex: pageNo ? pageNo : 1,
        pageSize: pageSize ? pageSize : 10,
        sortColumnName:
          sortColumn === "UserIPAddress"
            ? "UserIPAddress"
            : sortColumn === "vendorName"
            ? "VendorName"
            : sortColumn === "retailerName"
            ? "RetailerName"
            : sortColumn === "collectionNew"
            ? "CollectionNew"
            : sortColumn === "category"
            ? "Category"
            : sortColumn === "date"
            ? "Date"
            : sortColumn === "styleNumber"
            ? "StyleNumber"
            : sortColumn === "metalType"
            ? "MetalType"
            : sortColumn === "metalColor"
            ? "MetalColor"
            : sortColumn === "price"
            ? "Price"
            : "UserIPAddress",
        sortOrder: sorttype ? sorttype.toString() : sortOrderType.toString(),
        reporttype: "Dealer",
      };
      setDownloadCSVDataloading(true);
      ReportJewelryReportService.ExportDataToCSV(inputData)
        .then((response) => {
          setDownloadCSVDataloading(false);
          var fileName =
            "JewelryRetailerClickReport_" +
            new Date().toJSON().slice(0, 10).split("-").reverse().join("/") +
            ".csv";
          BlobToDownloadFile(response.data, fileName);
        })
        .catch((error) => {
          setDownloadCSVDataloading(false);
        });
    } catch (error) {
      setDownloadCSVDataloading(false);
    }
  };

  const handleGetRetailerDropdownData = () => {
    try {
      const dealerId = loginDetials.loginDetials.responseData.dealerId;
      let inputData = {
        dealerId: dealerId,
        reporttype: "Dealer",
      };
      AdminJewelryReportService.GetRetailerDropdownList(inputData)
        .then((response) => {
          var message = response.data.message;
          var responseData = response.data.responseData;
          if (message === "Success") {
            setRetailerList(responseData);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: rId,
        }));
      } else {
        rId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: e,
        }));
      }

      if (e.length == 0) {
        handleGetRetailerDropdownData();
      }
    }
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    var type = "";
    var column = "";
    //sorting
    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setsortOrderType(type);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setsortOrderType(type);
    } else {
      type = "DESC";
      column = "date";
      setsortOrderType(type);
    }
    const forDealerValue =
      loginDetials.loginDetials.responseData.dealerMemberTypeName === "Vendor"
        ? 1
        : 3;
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetTableChartData(
      dealerId,
      forDealerValue,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Jewelry Retailer Clicks Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block datepicker__block">
              <label> {traslate("Date Range")} </label>
              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days"),
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month"),
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()],
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>

          {dealerMembershipType === "Retailer" ? null : (
            <div className="col-md-4">
              <div className="input__block mobile__bottomspace">
                <label> {traslate("Retailer(s)")} </label>
                <Select
                  showSearch
                  showArrow
                  className="border__grey"
                  placeholder="Select one or more retailer "
                  optionFilterProp="children"
                  onChange={(e) => handleOnChange(e, "select", "retailers")}
                  notFoundContent="No Data Found"
                  mode="multiple"
                  value={selectedData.retailerIds}
                >
                  {retailerList.map((item, key) => {
                    return (
                      <Option key={key} value={item.dealerID}>
                        {" "}
                        {item.dealerCompany}{" "}
                      </Option>
                    );
                  })}
                </Select>
              </div>
            </div>
          )}

          <div className="col-md-4 mt-4 mobile-m-0">
            <div className="input__block">
              <button className="primary-btn mt-2" onClick={handleApplyFilter}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          <div className="col-lg-12 col-md-6">
            <div className="input__block">
              <label>
                <b>
                  {" "}
                  {traslate("Total Jewelry Clicks")} : {totalCount}{" "}
                </b>
                <Tooltip
                  placement="right"
                  title={traslate(
                    "Displays the total number of jewelry clicks through the currently specified date range above"
                  )}
                >
                  <img src={UnionIcon} alt="" />
                </Tooltip>
              </label>
            </div>
          </div>
          {!showChart ? (
            <Spin spinning={showLoader}>
              <div className="col-lg-12">
                <div className="chart__section">
                  {topVendorDealer !== null &&
                    topVendorDealer.length !== 0 &&
                    topVendorTotal.length !== 0 &&
                    JewelryVendorClickTableList &&
                    JewelryVendorClickTableList.length > 0 && (
                      <Pie
                        key={key}
                        onClick={(event) => {
                          const element = getElementAtEvent(JewelVendorChartRef.current, event);
                          if (!element.length) return;

                          const { index } = element[0];
                          setSelectedData((prevState) => ({
                            ...prevState,
                            vendor: topVendorDealerId[index],
                          }));
                        }}
                        ref={JewelVendorChartRef}
                        data={{
                          labels: topVendorDealer,
                          datasets: [
                            {
                              data: topVendorTotal,
                              backgroundColor: [
                                "rgb(14, 220, 232)",
                                "rgb(16, 150, 24)",
                                "rgb(255, 153, 0)",
                                "rgb(153, 0, 153)",
                                "rgb(0, 153, 198)",
                                "rgb(51, 102, 204)",
                                "rgb(220, 57, 18)",
                                "rgb(102, 170, 0)",
                                "rgb(221, 68, 119)",
                                "rgb(237, 237, 1)",
                              ],
                              borderColor: [
                                "rgb(14, 220, 232)",
                                "rgb(16, 150, 24)",
                                "rgb(255, 153, 0)",
                                "rgb(153, 0, 153)",
                                "rgb(0, 153, 198)",
                                "rgb(51, 102, 204)",
                                "rgb(220, 57, 18)",
                                "rgb(102, 170, 0)",
                                "rgb(221, 68, 119)",
                                "rgb(237, 237, 1)",
                              ],
                              borderWidth: 1,
                            },
                          ],
                        }}
                        width={300}
                        height={300}
                        options={{
                          maintainAspectRatio: false,
                          responsive: true,
                          plugins: {
                            datalabels: {
                              display: true,
                              align: "end",
                              color: "rgb(255, 255, 255)",
                              font: {
                                size: 12,
                              },
                              formatter: (value) => {
                                if (totalValueForPercentage) {
                                  let finalPercentage = (
                                    (value / totalValueForPercentage) *
                                    100
                                  ).toFixed(2);
                                  setTooltipPercentage(finalPercentage);
                                  if (finalPercentage >= 5) {
                                    return finalPercentage + "%";
                                  } else {
                                    return "";
                                  }
                                }
                              },
                            },
                            tooltip: {
                              enabled: true,
                              callbacks: {
                                label: function (context) {
                                  let label = context.label || "";
                                  let formattedValue =
                                    context.formattedValue || "";

                                  if (label) {
                                    label =
                                      label +
                                      " " +
                                      formattedValue +
                                      " (" +
                                      TooltipPercentage +
                                      "%" +
                                      ")";
                                  }
                                  return label;
                                },
                              },
                            },
                            legend: {
                              position: "left",
                            },
                          },
                        }}
                      />
                    )}
                </div>
              </div>
            </Spin>
          ) : (
            <div className="no-report-render">
              <div className="desc-space">
                <h4 className="text-center">{traslate("No Data Found")}</h4>
              </div>
            </div>
          )}

          <div className="col-lg-12 mt-3">
            <div className="options__div">
              <div className="row mb-4">
                <div className="col-lg-10 rules__div"></div>
                <div className="col-lg-2 crud__div">
                  <Button
                    className="primary-btn"
                    onClick={() => handleExportToCSV()}
                    loading={downloadCSVDataloading}
                  >
                    {" "}
                    <i className="fa fa-download"></i>{" "}
                    {traslate("Download CSV")}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-12 col-md-12">
            <div className="table__scroll">
              <Table
                className="pendjewelreq__tbl"
                columns={[
                  {
                    title: traslate("Product Image"),
                    dataIndex: "imagePathNew",
                    width: 120,
                    render: (item, data) => (
                      <>
                        {data.imagePathNew ? (
                          <span
                            onClick={() => imageClickHandler(data.imagePathNew)}
                          >
                            <img
                              src={data.imagePathNew}
                              alt="img"
                              className="product__img"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        ) : (
                          <span onClick={() => setDefaultVisible(true)}>
                            <img
                              src={DefaultImage}
                              alt="img"
                              className="product__img"
                              style={{ cursor: "pointer" }}
                            />
                          </span>
                        )}
                      </>
                    ),
                  },
                  {
                    title: traslate("User IP Address"),
                    dataIndex: "userIPAddress",
                    sorter: (a, b) => {},
                    width: 120,
                  },
                  ...(dealerMembershipType !== "Vendor" &&
                  dealerMembershipType !== "VendorDiamond"
                    ? [
                        {
                          title: traslate("Vendor"),
                          dataIndex: "vendorName",
                          width: 120,
                          sorter: (a, b) => {},
                        },
                      ]
                    : []),
                  ...(dealerMembershipType !== "Retailer"
                    ? [
                        {
                          title: traslate("Retailer"),
                          dataIndex: "retailerName",
                          width: 120,
                          sorter: (a, b) => {},
                        },
                      ]
                    : []),
                  {
                    title: traslate("Collection"),
                    dataIndex: "collectionNew",
                    sorter: (a, b) => {},
                    width: 120,
                  },
                  {
                    title: traslate("Category"),
                    dataIndex: "category",
                    sorter: (a, b) => {},
                    width: 120,
                  },
                  {
                    title: traslate("Date"),
                    dataIndex: "date",
                    sorter: (a, b) => {},
                    width: 120,
                    render: (row, item) => {
                      if (Object.keys(item.date).length !== 0) {
                        return (
                          <React.Fragment>
                            <span>
                              {moment(item.date).format(
                                "MM-DD-YYYY, hh:mm:ss A"
                              )}
                            </span>
                          </React.Fragment>
                        );
                      }
                    },
                  },
                  {
                    title: traslate("Style Number"),
                    dataIndex: "styleNumber",
                    sorter: (a, b) => {},
                    width: 120,
                  },
                  {
                    title: traslate("Metal Type"),
                    dataIndex: "metalType",
                    sorter: (a, b) => {},
                    width: 120,
                  },
                  {
                    title: traslate("Metal Color"),
                    dataIndex: "metalColor",
                    sorter: (a, b) => {},
                    width: 120,
                  },
                  {
                    title: traslate("Price"),
                    dataIndex: "price",
                    sorter: (a, b) => {},
                    width: 100,
                  },
                ]}
                pagination={{
                  total: totalCount,
                  pageSizeOptions: [10, 20, 50, 100],
                  responsive: true,
                  showSizeChanger: true,
                  pageSize: pageSize,
                  current: pageNo,
                }}
                loading={showTableLoader}
                dataSource={JewelryVendorClickTableList}
                scroll={{ x: 600, y: 800 }}
                onChange={handleTableASCDES}
              />
            </div>
          </div>
          <Modal
            title=""
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            width={500}
            footer={false}
          >
            <div className="">
              <img src={selectedImage} alt="img" className="img-fluid" />
            </div>
          </Modal>

          <Modal
            title=""
            centered
            visible={defaultVisible}
            onOk={() => setDefaultVisible(false)}
            onCancel={() => setDefaultVisible(false)}
            width={500}
            footer={false}
          >
            <div className="">
              <img src={DefaultImage} alt="img" className="img-fluid" />
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
};

export default JewelryVendorClickReportMain;
