import React, { useState, useEffect } from "react";
import { Select, Table, Spin, Modal,Tooltip } from "antd";
import { DatePicker } from "antd";
import traslate from "../../../i18n/translate";
import moment from "moment";
import AdminJewelryReportService from "../../../services/admin-jewelryReport-service";
import DoughnutChart from "./Doughnut";
import { useSelector } from "react-redux";
import UnionIcon from "../../../assets/images/icons/Union_5.svg";


/*Initial State for Binding Starts*/
const initialEventState = {
  dealerID: "",
  forDealer: "",
  fromDate: moment().subtract(30, "days"),
  toDate: moment(),
  retailerIds: [],
  vendorIds: []
};
/*Initial State for Binding Ends*/
const { RangePicker } = DatePicker;
const { Option } = Select;

const JewelryRetailerClicksReport = () => {
  const [selectedData, setSelectedData] = useState(initialEventState);
  const [vendorList, setVendorList] = useState([]);
  const [retailerList, setRetailerList] = useState([]);
  const [tableChartData, setTableChartData] = useState([]);
  const [topRetailerDealer, setTopRetailerDealer] = useState([]);
  const [retailerTotalValuePercentage, setRetailerTotalValuePercentage] =
    useState(0);
  const [topRetailerTotal, setTopRetailerTotal] = useState([]);
  const [showChart, setShowChart] = useState(false);
  const [showLoader, setShowLoader] = useState(true);
  const [visible, setVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const loginDetials = useSelector((state) => state.loginReducer);
  const [showTableLoader, setShowTableLoader] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalCount, setTotalCount] = useState(0);
  const [vendorType, setVendorType] = useState("");
  const [sortType, setSortType] = useState("desc");

  const data1 = [];
  const [key, setKey] = useState(true);

  useEffect(() => {
    if (loginDetials) {
      if (loginDetials.loginDetials) {
        if (loginDetials.loginDetials.responseData.logInSucceeded) {
          setSelectedData((prevState) => ({
            ...prevState,
            dealerID: loginDetials.loginDetials.responseData.dealerId
          }));
        }
      }
    }
    handleGetForDealer(loginDetials.loginDetials.responseData.dealerId);
    handleGetVendorDropdownData();
    handleGetRetailerDropdownData();
  }, []);

  const handleGetForDealer = (id) => {
    const initialEventState = {
      dealerID: id
    };
    setShowLoader(true);
    AdminJewelryReportService.GetForDealer(initialEventState).then(
      (response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setSelectedData((prevState) => ({
            ...prevState,
            forDealer: responseData.forDealer
          }));

          handleGetTopRetailerCoulmnData(id, responseData.forDealer);
          handleGetTableChartData(id, responseData.forDealer);
        }
      }
    );
  };
  const handleGetTopRetailerCoulmnData = (DealerId, forDealerId) => {
    const initialEventState = {
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate, //?moment(
      toDate: selectedData.toDate, //? moment(
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
      reporttype: "Global"
    };
    setShowLoader(true);
    setTopRetailerTotal([]);
    setTopRetailerDealer([]);
    AdminJewelryReportService.GetClicksBasedOnRetailer(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          let dealerName = [];
          let total = [];
          let showResp = responseData.every((element) => {
            return element.total == "0";
          });
          setShowChart(showResp);
          for (let i of responseData) {
            dealerName.push(i.dealerCompany);
            total.push(i.total);
          }
          let totalValuePercentage = total.reduce(
            (a, b) => (a = Number(a) + Number(b)),
            0
          );
          setTopRetailerTotal(total);
          setTopRetailerDealer(dealerName);
          setRetailerTotalValuePercentage(totalValuePercentage);
          setKey(!key);
        }
      })
      .finally(() => setShowLoader(false));
  };
  const handleGetTableChartData = async (
    DealerId,
    forDealerId,
    type,
    pageNumber,
    pageSize,
    sortColumn,
    sorttype
  ) => {
    const initialEventState = {
      dealerID: DealerId,
      forDealer: forDealerId,
      fromDate: selectedData.fromDate, //?moment(
      toDate: selectedData.toDate, //? moment(
      retailerIds: selectedData.retailerIds.toString(),
      vendorIds: selectedData.vendorIds.toString(),
      category: "",
      metalType: "",
      metalColorType: "",
      price: "",
      vendor: "",
      retailer: type ? type : "",
      reporttype: "Global",
      pageIndex: pageNumber ? pageNumber : 1,
      pageSize: pageSize ? pageSize : 10,
      sortColumnName:
        sortColumn == "UserIPAddress"
          ? "UserIPAddress"
          : sortColumn == "Vendor"
          ? "VendorName"
          : sortColumn == "Retailer"
          ? "RetailerName"
          : sortColumn == "Collection"
          ? "CollectionNew"
          : sortColumn == "Category"
          ? "Category"
          : sortColumn == "Date"
          ? "Date"
          : sortColumn == "StyleNumber"
          ? "StyleNumber"
          : sortColumn == "MetalType"
          ? "MetalType"
          : sortColumn == "MetalColor"
          ? "MetalColor"
          : sortColumn == "Price"
          ? "Price"
          : "date",
      sortOrder: sorttype ? sorttype.toString() : sortType.toString()
    };
    setShowTableLoader(true);
    AdminJewelryReportService.GetTableChartData(initialEventState)
      .then((response) => {
        let message = response.data.message;
        let responseData = response.data.responseData;
        if (message === "Success") {
          setTotalCount(responseData?.[0]?.totalRecords || 0);
          responseData.map((elem, index) => {
            data1.push({
              key: index,
              ProductImage: (
                <span onClick={() => imageClickHandler(elem.imagePathNew)}>
                  <img src={elem.imagePathNew} className="product__img" />
                </span>
              ),
              UserIPAddress: <span>{elem.userIPAddress}</span>,
              Vendor: (
                <span>{elem.vendorName === null ? "" : elem.vendorName}</span>
              ),
              Retailer: (
                <span>
                  {elem.retailerName === null ? "" : elem.retailerName}
                </span>
              ),
              Collection: <span>{elem.collectionNew}</span>,
              Category: <span>{elem.category}</span>,
              Date: <span>{elem.dateTime}</span>,
              StyleNumber: <span>{elem.styleNumber}</span>,
              MetalType: <span>{elem.metalType}</span>,
              MetalColor: <span>{elem.metalColor}</span>,
              Price: <span>{elem.price}</span>
            });
          });
          setTableChartData(data1);
        } else {
          setTotalCount(0);
        }
      })
      .catch((e) => console.log(e))
      .finally(() => {
        setShowTableLoader(false);
      });
  };
  const handleGetVendorDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "Dealer"
    };
    AdminJewelryReportService.GetVendorDropdownList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setVendorList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleGetRetailerDropdownData = () => {
    const dealerId = loginDetials.loginDetials.responseData.dealerId;
    let inputData = {
      dealerId: dealerId,
      reporttype: "Dealer"
    };
    AdminJewelryReportService.GetRetailerDropdownList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRetailerList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /*DatePicker Binding Starts*/
  const onDateChange = (dates, dateStrings) => {
    if (dates) {
      setSelectedData((prevState) => ({
        ...prevState,
        fromDate: moment.utc(dates[0]).toISOString(), //moment("2015-08-11T13:00:00.000000Z", "YYYY-MM-DDTHH:mm:ss.SSSSZ", true).format()
        toDate: moment.utc(dates[1]).toISOString()
      }));
    }
  };
  /*DatePicker Binding Ends*/
  const handleOnChange = (e, isSelect, name) => {
    if (isSelect === "select" && name === "vendors") {
      let vId = "";
      if (e.includes(0)) {
        vId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: vId
        }));
      } else {
        vId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          vendorIds: e
        }));
      }
      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeRetailerListHandler(vId);
      }
    } else if (isSelect === "select" && name === "retailers") {
      let rId = "";
      if (e.includes(0)) {
        rId = [0];
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: rId
        }));
      } else {
        rId = e;
        setSelectedData((prevState) => ({
          ...prevState,
          retailerIds: e
        }));
      }
      if (e.length == 0) {
        handleGetVendorDropdownData();
        handleGetRetailerDropdownData();
      } else {
        changeVendorListHandler(rId);
      }
    }
  };
  const changeRetailerListHandler = (data) => {
    data = data.toString();
    let inputData = {
      vendorIds: data
    };
    AdminJewelryReportService.ChangeRetailerList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setRetailerList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const changeVendorListHandler = (data) => {
    data = data.toString();
    let inputData = {
      retailerIds: data
    };
    AdminJewelryReportService.ChangeVendorList(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          setVendorList(responseData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const applyFilterHandler = () => {
    handleGetTopRetailerCoulmnData(
      selectedData.dealerID,
      selectedData.forDealer
    );
    setVendorType("");
    setPageNo(1);
    handleGetTableChartData(selectedData.dealerID, selectedData.forDealer);
    setPageSize(10);
  };
  const imageClickHandler = (img) => {
    setVisible(true);
    setSelectedImage(img);
  };

  const columns = [
    {
      title: traslate("Product Image"),
      dataIndex: "ProductImage",
      width: 90
    },
    {
      title: traslate("User IP Address"),
      dataIndex: "UserIPAddress",
      sorter: (a, b) => {},
      width: 90
    },
    {
      title: traslate("Vendor"),
      dataIndex: "Vendor",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Retailer"),
      dataIndex: "Retailer",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Collection"),
      dataIndex: "Collection",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Category"),
      dataIndex: "Category",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Date"),
      dataIndex: "Date",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Style Number"),
      dataIndex: "StyleNumber",
      sorter: (a, b) => {},
      width: 120
    },
    {
      title: traslate("Metal Type"),
      dataIndex: "MetalType",
      sorter: (a, b) => {},
      width: 100
    },
    {
      title: traslate("Metal Color"),
      dataIndex: "MetalColor",
      sorter: (a, b) => {},
      width: 100
    },
    {
      title: traslate("Price"),
      dataIndex: "Price",
      sorter: (a, b) => {},
      width: 120
    }
  ];

  const tableSortHandler = (type) => {
    setVendorType(type);
    setPageNo(1);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      type
    );
  };

  const handleTableASCDES = (pagination, filters, sorter) => {
    // ;
    var type = "";
    var column = "";

    if (sorter.order == "descend") {
      type = "DESC";
      column = sorter.field;
      setSortType(type);
    } else if (sorter.order == "ascend") {
      type = "ASC";
      column = sorter.field;
      setSortType(type);
    } else {
      type = "DESC";
      column = "date";
      setSortType(type);
    }
    setPageNo(pagination.current);
    setPageSize(pagination.pageSize);
    handleGetTableChartData(
      selectedData.dealerID,
      selectedData.forDealer,
      vendorType,
      pagination.current,
      pagination.pageSize,
      column,
      type
    );
  };

  return (
    <React.Fragment>
      <div className="pendjewelreq__section form__fields">
        <div className="row magento__mapping">
          <div className="col-md-12">
            <div className="heading__block">
              <h4 class="workarea__heading mt-0 mb-0">
                {traslate("Jewelry Vendor Clicks Report")}
              </h4>
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block datepicker__block">
              <label> {traslate("Date Range")} </label>

              <RangePicker
                className="date-range-input"
                ranges={{
                  Today: [moment(), moment()],
                  Yesterday: [
                    moment().subtract(1, "days"),
                    moment().subtract(1, "days")
                  ],
                  "Last 7 Days": [moment().subtract(7, "days"), moment()],
                  "Last 30 Days": [moment().subtract(30, "days"), moment()],
                  "This Month": [
                    moment().startOf("month"),
                    moment().endOf("month")
                  ],
                  "Last Month": [
                    moment().subtract(1, "month").startOf("month"),
                    moment().subtract(1, "months").endOf("month")
                  ],
                  "Last 90 Days": [moment().subtract(90, "days"), moment()],
                  "Year To Date": [moment().startOf("year"), moment()]
                }}
                format={"MM-DD-YYYY"}
                onChange={onDateChange}
                defaultValue={[moment().subtract(30, "days"), moment()]}
                defaultPickerValue={[moment().subtract(30, "days"), moment()]}
              />
            </div>
          </div>
          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Retailer(s)")} </label>
              <Select
                showSearch
                showArrow
                className="border__grey"
                placeholder="Select one or more retailer "
                optionFilterProp="children"
                onChange={(e) => handleOnChange(e, "select", "retailers")}
                notFoundContent="No Data Found"
                mode="multiple"
                value={selectedData.retailerIds}
              >
                {retailerList.map((item, key) => {
                  return (
                    <Option key={key} value={item.dealerID}>
                      {item.dealerCompany}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <label> {traslate("Vendor(s)")} </label>
              <Select
                className="border__grey"
                showSearch
                mode="multiple"
                showArrow
                placeholder="Select one or more vendor"
                optionFilterProp="children"
                value={selectedData.vendorIds}
                onChange={(e) => handleOnChange(e, "select", "vendors")}
              >
                {vendorList.map((item, key) => {
                  return (
                    <Option key={item.dealerID} value={item.dealerID}>
                      {item.dealerCompany}
                    </Option>
                  );
                })}
              </Select>
            </div>
          </div>

          <div className="col-md-4">
            <div className="input__block">
              <button className="primary-btn mt-2" onClick={applyFilterHandler}>
                {" "}
                {traslate("Apply Filter")}{" "}
              </button>
            </div>
          </div>
          {
            topRetailerDealer.length > 0 &&
             (
              <div className="col-lg-12 col-md-6">
                <div className="input__block">
                  <label>
                    {totalCount !== 0 && (
                      <b>
                        {traslate("Total Jewelry Clicks")} : {totalCount}
                      </b>
                    )}
                    <Tooltip
                      placement="right"
                      title={traslate(
                        "Displays the total number of jewelry clicks through the currently specified date range above"
                      )}
                    >
                      <img src={UnionIcon} alt="img" />
                    </Tooltip>
                  </label>
                </div>
              </div>
            )}

          {!showChart ? (
            <Spin spinning={showLoader}>
              <div className="col-lg-12">
                <div className="chart__section">
                  {topRetailerDealer !== null &&
                    topRetailerDealer.length >= 1 &&
                    topRetailerTotal.length >= 1 &&
                    retailerTotalValuePercentage !== 0 && (
                      <DoughnutChart
                        key={key}
                        label={topRetailerDealer}
                        data={topRetailerTotal}
                        typeGetter={tableSortHandler}
                        totalValuePercentage={retailerTotalValuePercentage}
                      />
                    )}
                </div>
              </div>
              <div className="col-lg-12 col-md-12">
                <Table
                  className="pendjewelreq__tbl"
                  columns={columns}
                  dataSource={tableChartData}
                  scroll={{ x: 600, y: 800 }}
                  loading={showTableLoader}
                  pagination={{
                    total: totalCount,
                    pageSizeOptions: [10, 20, 50, 100],
                    responsive: true,
                    showSizeChanger: true,
                    pageSize: pageSize,
                    current: pageNo
                  }}
                  onChange={handleTableASCDES}
                />
              </div>
            </Spin>
          ) : (
            <div className="no-report-render">
              <div className="desc-space">
                <h4 className="text-center">{traslate("No Data Found")}</h4>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        title=""
        centered
        visible={visible}
        onOk={() => setVisible(false)}
        onCancel={() => setVisible(false)}
        width={500}
        footer={false}
      >
        <div className="">
          <img src={selectedImage} className="img-fluid" />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default JewelryRetailerClicksReport;
